@use "sass:math"; 
@import '_mixin';

// import fonts
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');

// font setting
$lora_font: 'Lora', serif;
$yugo_font:  "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
$yumin_font:  "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$mont_font: 'Montserrat', sans-serif;
$noto_sans:'Noto Sans JP', sans-serif;
$noto_serif:'Noto Serif JP', serif;

// color setting
$white_color : #FFFFFF;
$line_color : #F2F2F2;
$gray_color : #B2B2B2;
$primary_bg_color:#F8F5EC;
$primary_dark_bg_color:#F8F5EC;
$black_color:#0B0B0B;
$accent_color:#A58457;

$catering_color :#2B2738;
$catering_text_color : #3B4043;

/* rsc */
$rsc_brown:#5D3500;
$rsc_primary:#F5A602;
$rsc_black:#2C2C2C;
$rsc_beige:#F6F2E7;
$rsc_yellow: #FFCC40;

// rievenhouse
$rh_bg_primary:#3E2104;

$dark_green : #334644;

/* constant */
:root {
  $size: 5;
  @while $size <= 500 {
    --size-#{$size}: calc(#{$size} / 750 * 100vw);
    $size: $size + 5;
  }
}

@include pc {
	:root {    
    $size: 5;
    @while $size <= 500 {
      --size-#{$size}: #{$size}px;
      $size: $size + 5;
    }
  }
}

// font-weight
@mixin font-weight($weight) {
	$weights: (
		thin: 100,
		light: 300,
		// normal: 400,
		medium: 300,
		semi: 600,
		bold: 600,
		black: 900,
	);
	$output: $weight;
	@if map-has-key($weights, $weight) {
		$output: map-get($weights, $weight);
	}
	font-weight: $output;
}

// base font size
@mixin font-size($size, $base: 10) {
	font-size: $size + px;
	font-size: math.div($size , $base) + rem;
}


//px-vw;
@function get_vw($size, $viewport: 750) {
	$math: calc($size / $viewport * 100vw);
	@return math.div(($math * 1000) , 1000);
}
@mixin fz-vw($font_size) {
	font-size: get_vw($font_size);
}
@mixin img-vw($img_size) {
	width: get_vw($img_size);
}
@mixin p-vw($property, $size) {
	#{$property}: get_vw($size);
}

// line-height
@mixin line-height($psd-size , $font-size) {
	line-height: math.div(calc($psd-size / $font-size * 10000), 10000) 
}

// flex
@mixin flex {
  display: flex;
  justify-content:center;
  align-items: center;
}

// center block
@mixin position-center {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  display: block;
}

// pseudo-elements
@mixin pseudo-elements($bg-image) {
  content: '';
  display: block;
  background-image: url($bg-image);
  background-size: contain;
  background-repeat: no-repeat;
}





