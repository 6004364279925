@charset "UTF-8";

/*====================================================================
 HEADER CSS
====================================================================*/
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background: #FFF;
  &__inner {
    height: var(--size-80);
    padding: 0 var(--size-30);
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include sp {
      height: var(--size-100);
    }
  }
  .c_logo {
    width: var(--size-200);
    @include sp {
      @include p-vw(width, 310);
    }
  }
  .c_global_navi {
    display: flex;
    font-family: $noto_sans;
    @include font-weight(bold);
    letter-spacing: 0.05rem;
    @include sp {
      display: none;
      position: absolute;
      width: 100%;
      z-index: 9;
      background: #FFF;
      top: var(--size-100);
      left: 0;
      height: 100vh;
      padding-top: var(--size-60);
      
    }
    .item {
      a,span {
        height: 80px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        @include sp {
          height: var(--size-120);
          font-size: get_vw(32);
        }
        &:hover {
          opacity: 1;
          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background: $gray_color;
            position: absolute;
            left: 0;
            bottom: 1px;
            @include sp {
              display: none;
            }
          }
        }
        @include sp {
          justify-content: center;
        }
      }
      @include sp {
        span {
          height: var(--size-70);
          align-items: flex-end;
          margin-bottom: var(--size-20);
        }
      }
      span.is-active {
        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background: $gray_color;
          position: absolute;
          left: 0;
          bottom: 1px;
          @include sp {
            display: none;
          }
        }
      }
      .c_sub_global_navi {
        a {
          height: 42px;
          font-size: 1.6rem;
          @include sp {
            height: var(--size-70);
          }
        }
      }
    }
    .item + .item {
      margin-left: var(--size-40);
      @include sp {
        margin-left: 0;
      }
    }    
    .c_global_link {
      display: flex;
      align-items: center;
      &:after {
        @include pseudo-elements('../../img/cmn/icon_blank.svg');
        width: 11px;
        height: 10px;
        margin-left: var(--size-5);
      }
    }
  }  
}

// sub global navi
.l_sub_global_navi {
  position: absolute;
  z-index: 999;
  width: 100%;
  background: #F0F0F0;
  display: none;
  left: 0;
  top: 80px;
  @include sp {
    display: block;
    position: initial;
    background: none;
  }
  .c_sub_global_navi {
    padding: var(--size-20) 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sp {
      display: block;
      padding: 0;
    }
    li {
      a {
        color: $gray_color;
        padding: var(--size-10) var(--size-40);
        display: block;
        font-family: $noto_sans;
        font-size: 1.6rem;
        @include font-weight(bold);
        @include sp {
          border: none;
          font-size: get_vw(32);
          padding: 0;
        }
      }
    }
    li + li {
      margin-left: var(--size-40);
      @include sp {
        margin-left: 0;
      }
    }
  }
}



/*====================================================================
 HEADER HAMBURGER CSS
====================================================================*/
.c_header-hamburger-btn {
  display: none;
  @include sp {
    display: block;
  }
}

@include sp {
  .c_header-hamburger-btn {
    position: absolute;
    height: var(--size-100);
    width: var(--size-100);
    top: 0;
    right: 0;
    @include pc {
      display: none;
    }
    .c_hh_btn {
      position: absolute;
      top: var(--size-25);
      left: 0;
      span {
        background: $black_color;
        width: var(--size-60);
        transition: .3s;
        @include p-vw(height, 6);
        display: block;
        &:first-child {
          position: absolute;
          top: 0;
        }
        &:nth-child(2) {
          position: absolute;
          @include p-vw(top, 21);
        }
        &:nth-child(3) {
          position: absolute;
          @include p-vw(top, 42);
        }
      }
    }
    &.is-active {
      .c_hh_btn {
        span {
          transition: .3s;
          position: absolute;
          &:first-child {
            transform: rotate(45deg);
            @include p-vw(top, 24);
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            @include p-vw(top, 24);
          }
        }
        // is-active
      }
    }
  }
}
