@charset "UTF-8";
@import '../_mixin';
@import '../_config';
@import '../inc/_header-default';

/*====================================================================
 TOP PAGE COVER CSS
====================================================================*/
.c_top_cover {
  display: flex;
  @include sp {
    display: block;
  }
  .item {
    width: calc(100% / 3);
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center; 
    position: relative;
    @include sp {
      width: 100%;
    }
    a {
      display: block;
      height: 670px;
      @include sp {
        @include p-vw(height, 404);
      }
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: $black_color;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: .5s;
      }
      &:hover {
        &:before {
          transition: .5s;
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background: $black_color;
          opacity: 0.44;
          position: absolute;
          top: 0;
          left: 0;
        }
        .c_title_wrap {
          background: none;
        }
        opacity: 1;
        .c_title {
          position: relative;
          display: inline-block;
          &:before, &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: -5px;
            left: 50%;
            background: #7162A0;
            transform: translate(-50%, -50%);
          }
          &._ja {
            display: block;
            &:before, &:after  {
              display: none;
            }
          }
        }
        .c_icon {
          transform: .5s;
          top: 10px;
          position: relative;
        }
      }
    }
    .link_catering:hover {
      .c_title:before, .c_title:after {
        background: #7162A0;
      }
    }
    
    @include sp  {
      .link_conveni {
        .c_title_wrap {
          left: 0;
          right: initial;
          .c_title {
            &:before, &:after {
              background: #6CA06E;  
            }
          } 
        }
      }
      .link_restaurent {
        .c_title_wrap {
          .c_title {
            &:before, &:after {
              background: #DDA54B;  
            }
          } 
        }
      }
    }
    
    .link_conveni:hover {
      .c_title:before, .c_title:after {
        background: #6CA06E;
      }
    }
    .link_restaurent:hover {
      .c_title:before, .c_title:after {
          background: #DDA54B;
      }
    }
    

    &._type01 {
      background-image: url(../../img/top/top_cover01.png);
      @include sp {
        background-image: url(../../img/top/top_cover01_sp.png);
      }
    }
    &._type02 {
      background-image: url(../../img/top/top_cover02.png);
      @include sp {
        background-image: url(../../img/top/top_cover02_sp.png);
      }
    }
    &._type03 {
      background-image: url(../../img/top/top_cover03.png);
      @include sp {
        background-image: url(../../img/top/top_cover03_sp.png);
      }
    }
    
    .c_title_wrap {
      background: rgba(11,11,11,0.44);
      color: #FFF;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: var(--size-25) 0 var(--size-35);
      transition: .5s;
      @include sp {
        top: 0;
        right: 0;
        @include p-vw(width, 356);
        padding-top: var(--size-160);
      }
      &:hover {
        background: rgba(11,11,11,0);
      }
      .c_title {
        font-size: 4rem;
        font-family: $lora_font;
        @include font-weight(normal);
        line-height: 1;
        letter-spacing: 0.04rem;
        position: relative;
        display: inline-block;
        @include sp {
          font-size: get_vw(44);
        }
        &:before , &:after {
          content: '';
          display: block;
          width: 0;
          height: 1px;
          position: absolute;
          bottom: -5px;
          left: 50%;
          background: #7162A0;
          transition: 0.5s;
          transform: translate(-50%, -50%);
          @include sp {
            width: 100%;
          }
        }
        &._ja {
          font-family: $noto_sans;
          font-size: 1.6rem;
          line-height: 1;
          padding: var(--size-15) 0 var(--size-10);
          display: block;
          @include sp {
            font-size: get_vw(24);
          }
          &:before , &:after {
            display: none;
          }
        }
      }
    }
    
    .c_icon {
      width: 6px;
      height: 20px;
      margin: 0 auto;
      top: 0;
      position: relative;
      transition: .5s;
      @include sp {
        @include p-vw(width, 8);
        @include p-vw(height, 24);
      }
    }
  }
  .item + .item {
    margin-left: var(--size-5);
    @include sp {
      margin-left: 0;
      margin-top: var(--size-10);
    }
  }
}


.top_info {
  background: $black_color;
  padding: var(--size-40) 0 var(--size-35);
  @include sp {
    padding: var(--size-50) var(--size-40);
    .l_inner {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .c_text_wrap {
    font-family: $noto_sans;
    border: 1px solid $accent_color;
    color: $white_color;
    text-align: center;
    padding: var(--size-20) 0 var(--size-20);
    @include sp {
      padding: var(--size-30);
    }
    .c_hedding {
      font-size: 1.8rem;
      @include font-weight(bold);
      line-height: 1;
      margin-bottom: var(--size-10);
      @include sp {
        font-size: get_vw(32);
        margin-bottom: var(--size-20);
      }
    }
    @include sp {
      .c_text {
        a {
          text-decoration: none;
          @include font-weight(medium);
        }
      }
    }
  }
}

/*====================================================================
 TOP PAGE COMPANY CSS
====================================================================*/
.top_company {
  .l_inner {
    position: relative;
    margin: var(--size-120) auto var(--size-140);
    @include sp {
      @include p-vw(margin-bottom, 480);
      padding-left: 0;
      padding-right: 0;
      
    }
    .image {
      width: 712px;
      @include sp {
        width: 100%;
      }
    }
    .text_wrap {
      background: $primary_bg_color;
      position: absolute;
      right: 0;
      top : 180px;
      padding: var(--size-55) var(--size-50) var(--size-70) var(--size-50);
      @include sp {
        @include p-vw(top, 400);
      }
      .heading {
        line-height: 2.2;
        @include font-weight(normal);
        @include line-height(50 , 22);
        letter-spacing: 0.05rem;
        @include sp {
          font-size: get_vw(40);
          text-align: left;
          @include line-height(40 , 20);
        }
      }
      .c_btn {
        position: absolute;
        bottom: -45px;
        right: 25px;
        @include sp {
          @include p-vw(right, 148);
        }
      }
    }
  }
}

/*====================================================================
 CATERING CSS
====================================================================*/
.catering {
  text-align: center;
  margin-bottom: var(--size-50);
  @include sp {
    .l_inner {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .heading {
    margin-bottom: var(--size-70);
  }
}

.l_catering_box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap : 0 24px;
  margin-top: var(--size-60);
  @include sp {
    display: block;
  }
 }

.c_top_card {
  max-width: 528px;
  @include sp {
    max-width: 100%;
  }
  .c_section_title {
    margin-bottom: var(--size-30);
  }
  .image {
    position: relative;
  }
  .c_text_contents {
    margin-left: 38px;
    margin-right: 38px;
    background: $white_color;
    margin-top: -28px;
    position: relative;
    z-index: 1;
    padding: var(--size-30) var(--size-30)  var(--size-30);
    @include sp {
      margin-left: var(--size-40);
      margin-right: var(--size-40);
      padding: var(--size-60) var(--size-40) var(--size-50) var(--size-40);
    }
  }
  .c_logo {
    height: 60px;
    width: fit-content;
    margin: 0 auto;
    @include sp {
      height: var(--size-100);  
    }
    img {
      width: auto;
      height: 60px;
      @include sp {
        height: var(--size-100);  
      }
    }
  }
  .c_text {
    text-align: left;
    margin: var(--size-25) auto var(--size-35);
    @include sp {
      margin: var(--size-60) auto var(--size-50);
    }
    &._mh {
      min-height: 96px;
      @include sp {
        min-height: initial;
      }
    } 
  }
}

.c_top_card_type02 {
  margin: var(--size-30) 0 var(--size-100);
  @include sp {
    margin: 0;
    padding-left: var(--size-40);
    padding-right: var(--size-40);
  }
  a {
    display: flex;
    align-items: center;
    max-width: 528px;
    border: 1px solid $gray_color;
    box-sizing: border-box;
    position: relative;
    &:after {
      @include pseudo-elements('../../img/cmn/right_arrow.svg');
      width: 20px;
      height: 6px;
      position: absolute;
      bottom: var(--size-15);
      right: var(--size-15);
    }
  }

  .image {
    flex-basis: 194px;
  }
  .c_text_contents {
    flex: 1;
    font-size: 2.2rem;
    @include font-weight(semi);
  }
}
@include sp {
  .c_top_card + .c_top_card {
    margin-top: var(--size-80);
  }
  
  .c_top_card_type02 + .c_top_card_type02 {
    margin-top: var(--size-30);
    margin-bottom: var(--size-140);
  }
  
}

/*====================================================================
 NEWS CSS
====================================================================*/
.top_news {
  padding: var(--size-70) 0 var(--size-40);
  @include sp {
    padding-bottom: var(--size-120);
  }
  .l_inner {
    text-align: center;
    @include sp {
      padding-left: var(--size-40);
      padding-right: var(--size-40);
    }
  }
}
.l_news_wrap {
  margin: var(--size-30) 0 var(--size-20);
  border-top: 1px solid $black_color;
  border-bottom: 1px solid $black_color;
  @include sp {
    margin: var(--size-40) 0 var(--size-40);
  }
  .item {
    a {
      display: flex;
      align-items:center;
      padding: var(--size-30);
      @include sp {
        flex-wrap: wrap;
        padding-left: 0;
        padding-right: 0;
      }
      .date,.category{
        font-family: $noto_sans;
      }
      @include sp {
        .date {
          font-size: get_vw(28);
        }
      }
      .category {
        background: $black_color;
        color: $white_color;
        font-size: 1.4rem;
        padding: var(--size-5) var(--size-10);
        margin-left: var(--size-25);
        margin-right: var(--size-25);
        min-width: 121px;
        text-align: center;
        @include sp {
          font-size: get_vw(24);
          @include p-vw(min-width,188);
        }
      }
      .c_text {
        text-decoration: underline;
        flex: 1;
        word-break: break-word;
        text-align: left;
        @include sp {
          width: 100%;
          text-align: left;
          margin-top: var(--size-10);
          word-break: initial;
        }
      }
    }
  }
  
  .item + .item {
    border-top: 1px solid $gray_color;
  }
  
}

/*====================================================================
 EMERGENCY CSS
====================================================================*/
.top_emergency{
  padding: var(--size-40) 0 var(--size-35);
  background-color: #F8F5EC;
  font-family: $noto_sans;
  font-weight: 400;
  @include sp {
    padding: var(--size-50)  var(--size-40);
    .l_inner {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .l_emergency_wrap{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 30px 0;
    padding: var(--size-20);
    font-size: 16px;
    background-color: #fff;
    border: 1px solid #0B0B0B;
    @include sp {
      font-size: 14px;
    }
    li{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      @include sp {
        flex-wrap: wrap;
      }
      time{
        margin-top: 1px;
        margin-right: 20px;
        font-size: 15px;
        flex-shrink: 0;
        @include sp {
          font-size: 12px;
        }
      }
      a{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: underline;
        text-underline-offset: 2px;
        &:hover{
          text-decoration: none;
        }
        &:after{
          display: block;
          content: "";
          width: 6px;
          height: 11px;
          margin-left: 10px;
          background-image: url(../../img/top/icon-arrow.svg);
          background-size: 100%;
          background-repeat: no-repeat;
          flex-shrink: 0;
        }
        p{
          display: inline;
          margin-bottom: 0;
          text-align: justify;
          /*&:after{
            display: inline-block;
            content: "";
            width: 6px;
            height: 11px;
            margin-left: 10px;
            background-image: url(../../img/top/icon-arrow.svg);
            background-size: 100%;
            background-repeat: no-repeat;
            flex-shrink: 0;
          }*/
        }
        &.-disabled{
          text-decoration: none;
          pointer-events: none;
          &:after{
            display: none;
          }
        }
        &.-limit{
          @include sp {
            width: 84%;
          }
          p{
            @include sp {
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              line-clamp: 1;
            }
          }
          @include sp {
            &:after{
              display: none;
            }
          }
        }
      }
      div{
        display: none;
        &.sp-more{
          @include sp {
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 12px;
            text-decoration: underline;
            width: 16%;
          }
        }
        &.-more,
        &.-close{
          &:after{
            display: block;
            content: "";
            width: 7px;
            height: 4px;
            margin-top: 3px;
            margin-left: 3px;
            background-image: url(../../img/top/icon-more-arrow.svg);
            background-size: 100%;
            background-repeat: no-repeat;
            flex-shrink: 0;
          }
        }
        &.-close{
          margin-left: auto;
          &:after{
            background-image: url(../../img/top/icon-close-arrow.svg);
          }
        }
      }
    }
  }
}