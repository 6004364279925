@charset "UTF-8";

$breakpoint_sp: 768;
$breakpoint_tb: 1260;
$breakpoint_pc: 1006;

@mixin sp {
  @media screen and (max-width: ($breakpoint_sp + px)) {
    @content;
  }
}
@mixin tb {
  @media screen and (max-width: ($breakpoint_tb + px)) {
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: ($breakpoint_pc + px)) {
    @content;
  }
}